<template>
  <div>
    <!-- <div class="tmHeader__header_small">{{ header }}</div> -->
    <show-case :sku="NutberryNutsCards" />
  </div>
</template>

<script>
import ShowCase from "../components/ShowCase.vue";

import NBpeanutRS100 from "../assets/NUTBERRY/NBnuts/NB-almondrs-100.jpg";
import NBwalnut100 from "../assets/NUTBERRY/NBnuts/NB-walnut-100.jpg";
import NBpinenut100 from "../assets/NUTBERRY/NBnuts/NB-pinenut-100.jpg";
import NBcashewR100 from "../assets/NUTBERRY/NBnuts/NB-cashwer-100.jpg";
import NBcashew100 from "../assets/NUTBERRY/NBnuts/NB-cashewd-100.jpg";
import NBalmondR100 from "../assets/NUTBERRY/NBnuts/NB-almondr-100.jpg";
import NBalmond100 from "../assets/NUTBERRY/NBnuts/NB-almondd-100.jpg";
import NBpistachiosR100 from "../assets/NUTBERRY/NBnuts/NB-phistahosrs-100.jpg";
import NBhuzelnutR100 from "../assets/NUTBERRY/NBnuts/NB-hzelnatr-100.jpg";
import NBhuzelnut100 from "../assets/NUTBERRY/NBnuts/NB-huzelnutd-100.jpg";
//мультикартинки
import NBpeanutMulti from "../assets/NUTBERRY/NBnuts/NB-peanutrs-multi.jpg";
import NBwalnutMulti from "../assets/NUTBERRY/NBnuts/NB-walnut-multi.jpg";
import NBpinenutMulti from "../assets/NUTBERRY/NBnuts/NB-pinenut-multi.jpg";
import NBcashewMulti from "../assets/NUTBERRY/NBnuts/NB-cashewr-multi.jpg";
import NBalmondMulti from "../assets/NUTBERRY/NBnuts/NB-almond-multi.jpg";
import NBpistachiosMulti from "../assets/NUTBERRY/NBnuts/NB-phistahos-multi.jpg";
import NBhuzelnutMulti from "../assets/NUTBERRY/NBnuts/NB-huzelnut-multi.jpg";

export default {
  name: "NutberryNuts",
  components: {
    ShowCase,
  },
  data() {
    return {
      header: "Орехи оптимального качества",
      NutberryNutsCards: [
        {
          picture: NBpeanutRS100,
          pictureMulti: NBpeanutMulti,
          text: "NUTBERRY Арахис жареный соленый 40, 100, 220 г",
          id: 2,
          description:
            "Состав: ядра арахиса жареные, соль поваренная пищевая, масло растительное – смесь (масла растительные рафинированные дезодорированные: подсолнечное, пальмовый олеин, антиокислитель Е319, антиокислитель Е330,  пеногаситель Е900).<br>Продукт может содержать следы кунжута, орехов.<br>Пищевая ценность в 100 г: Белки 28,3 г, Жиры 48,7 г, Углеводы 9,9<br>Энергетическая ценность 596 Ккал.<br>Хранить при температуре +20°С±3°С и относительной влажности воздуха не более 70%. Срок годности 6 месяцев.<br>ТУ 9146-006-60458573-14<br>Страна изготовитель: Россия",
        },
        {
          picture: NBwalnut100,
          pictureMulti: NBwalnutMulti,
          text: "NUTBERRY Грецкий орех 100, 220 г",
          id: 4,
          description:
            "Состав: ядра грецкого ореха сушеные.<br>Продукт может содержать фрагменты скорлупы, следы арахиса, кунжута, других орехов.<br>Пищевая ценность в 100 г: Белки 14,8 г, Жиры 64 г, Углеводы 13,7<br>Энергетическая ценность 698 Ккал.<br>Хранить при температуре не выше +20°С и относительной влажности воздуха не более 70%. Срок годности 6 месяцев.<br>ТУ 9761-004-60458573-12<br>Страна изготовитель: Россия",
        },
        {
          picture: NBpinenut100,
          pictureMulti: NBpinenutMulti,
          text: "NUTBERRY Кедровый орех 40, 100 г",
          id: 7,
          description:
            "Состав: ядра кедрового ореха сушеные.<br>Продукт может содержать следы арахиса, кунжута, других орехов.<br>Пищевая ценность в 100 г: Белки 23,7 г, Жиры 60 г, Углеводы 12,5<br>Энергетическая ценность 674 Ккал.<br>Энергетическая ценность 645 Ккал.<br>Хранить при температуре не выше +20°С и относительной влажности воздуха не более 70%. Срок годности 6 месяцев.<br>ТУ 9761-004-60458573-12<br>Страна изготовитель: Россия",
        },
        {
          picture: NBcashewR100,
          pictureMulti: NBcashewMulti,
          text: "NUTBERRY Кешью жареный 40, 100, 220 г",
          id: 9,
          description:
            "Состав: ядра кешью жареные.<br>Продукт может содержать фрагменты скорлупы, следы арахиса, кунжута, других орехов<br>Пищевая ценность в 100 г: Белки 26,0 г, Жиры 55,3 г, Углеводы 13,0<br>Энергетическая ценность 650,7 Ккал.<br>Условия хранения: при температуре не выше +20°С и относительной влажности воздуха не более 70%. Срок годности 12 месяцев.<br>ТУ 9761-004-60458573-12<br>Страна изготовитель: Россия",
        },
        {
          picture: NBcashew100,
          text: "NUTBERRY Кешью сушеный 100 г",
          id: 11,
          description:
            "Состав: ядра кешью сушеные.<br>Продукт может содержать фрагменты скорлупы, следы арахиса, кунжута, других орехов<br>Пищевая ценность в 100 г: Белки - 17,5 г, Жиры 42,2 г, Углеводы 30,5<br>Энергетическая ценность 572 Ккал.<br>Условия хранения: при температуре не выше +20°С и относительной влажности воздуха не более 70%. Срок годности 12 месяцев.<br>ТУ 9761-004-60458573-12<br>Страна изготовитель: Россия",
        },
        {
          picture: NBalmondR100,
          pictureMulti: NBalmondMulti,
          text: "NUTBERRY Миндаль жареный 100, 220 г",
          id: 13,
          description:
            "Состав: ядра миндаля жареные.<br>Продукт может содержать фрагменты скорлупы, следы арахиса, кунжута и других орехов.<br>Пищевая ценность в 100 г: Белки 18,0 г, Жиры 56,9 г, Углеводы 13,2<br>Энергетическая ценность 633 Ккал.<br>Хранить при температуре не выше +20°С и относительной влажности воздуха не более 70%. Срок годности 12 месяцев.<br>ТУ 9761-004-60458573-12<br>Страна изготовитель: Россия",
        },
        {
          picture: NBalmond100,
          text: "NUTBERRY Миндаль сушеный 100 г",
          id: 12,
          description:
            "Состав: ядра миндаля сушеные.<br>Продукт может содержать фрагменты скорлупы, следы арахиса, кунжута и других орехов.<br>Пищевая ценность в 100 г: Белки 18,6 г, Жиры 57,7 г, Углеводы 13,6<br>Энергетическая ценность 645 Ккал.<br>Условия хранения: при температуре не выше +20°С и относительной влажности воздуха не более 70%. Срок годности 12 месяцев.<br>ТУ 9761-004-60458573-12<br>Страна изготовитель: Россия",
        },
        {
          picture: NBpistachiosR100,
          pictureMulti: NBpistachiosMulti,
          text: "NUTBERRY Фисташка жареная соленая 40, 100, 220 г",
          id: 16,
          description:
            "Состав: фисташки в скорлупе жареные, соль морская, регулятор кислотности – кислота лимонная.<br>Продукт может содержать следы арахиса, кунжута и орехов.<br>Пищевая ценность в 100 г: Белки 28,58 г, Жиры 51,6 г, Углеводы 16,4 г<br>Энергетическая ценность 642 Ккал.<br>Хранить при температуре не выше +20°С и относительной влажности воздуха не более 70%. Срок годности 12 месяцев.<br>ТУ 9761-004-60458573-12<br>Страна изготовитель: Россия",
        },
        {
          picture: NBhuzelnutR100,
          pictureMulti: NBhuzelnutMulti,
          text: "NUTBERRY Фундук жареный 100, 220 г",
          id: 19,
          description:
            "Состав: ядра фундука жареные , масло подсолнечное, комплексная пищевая добавка «Гриндокс» (агент влагоудерживающий Е1520, антиоксилители (Е319, Е330)).<br>Продукт может содержать фрагменты скорлупы, следы арахиса, кунжута, других орехов.<br>Пищевая ценность в 100 г: Белки 18,0 г, Жиры 56,7 г, Углеводы 13,1г<br>Энергетическая ценность 632 Ккал.<br>Хранить при температуре не выше +20°С и относительной влажности воздуха не более 70%. Срок годности 9 месяцев.<br>ТУ 9761-004-60458573-12<br>Страна изготовитель: Россия",
        },
        {
          picture: NBhuzelnut100,
          text: "NUTBERRY Фундук сушеный 100 г",
          id: 18,
          description:
            "Состав: ядра фундука сушеные<br>Продукт может содержать фрагменты скорлупы, следы арахиса, кунжута, других орехов.<br>Пищевая ценность в 100 г: Белки 12,6 г, Жиры 62,4 г, Углеводы 13,7 г<br>Энергетическая ценность 679 Ккал.<br>Хранить при температуре не выше +20°С и относительной влажности воздуха не более 70%. Срок годности 12 месяцев.<br>9761-004-60458573-12<br>Страна изготовитель: Россия",
        },
      ],
    };
  },
};
</script>
